<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Nova Questão">
      <form-question action="add"></form-question>
    </base-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    page: {
      title: 'Questões'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Questões',
        disabled: false,
        to: '/question/list'
      },
      {
        text: 'Nova Questão',
        disabled: false,
        to: '/question/add'
      }
    ]
  })

}
</script>
